function caisseVenteController($uibModal, $translate, $scope, WS, $window, AppSettings, $filter) {
    'ngInject';
    const vm = this;
   
    vm.filter_type = 'code_a_barre';
    vm.type_entete = 'bl';
    vm.bg_color = {
        'bl': '#262b4e',
        'avoir': '#e89510',
        'commande': '#277850',
        'facture': '#6b0a56',
        'devis': '#067f97',
        'cadeaux': '#c9484a',
    }
    vm.asci_calc = [
        8,53,219,111,103,104,105,106,100,101,102,109,97,98,99,107,96,110,13
    ];



    // default test data
    vm.newbl = {};
    vm.soussociete_code='1';
    vm.depot_code='U06';
    vm.client_code='90';
    vm.tarif='tarif';

    

    vm.distributeur = {};
 
    vm.depot = {}

    vm.currentPage='PAGE_CAISSE';
    vm.currentCaisseLeft='PRODUCT';
    vm.currentCaisseAnimate = {
        'PRODUCT': 'animate__fadeInRight',
        'DEPOTS': 'animate__fadeOutLeft',
        'CLIENTS': 'animate__fadeOutLeft',
    }
    vm.changePageCaisse = (CURRENT_PAGE='PAGE_CAISSE', CURRENT_LEFT_SIDEBAR='PRODUCT') => {
        
        vm.currentPage= vm.currentPage==CURRENT_PAGE ? 'PAGE_CAISSE' : CURRENT_PAGE;
        vm.currentCaisseLeft= vm.currentCaisseLeft==CURRENT_LEFT_SIDEBAR ? 'PRODUCT' : CURRENT_LEFT_SIDEBAR;

        //animation : 
        vm.currentCaisseAnimate['PRODUCT'] = vm.currentCaisseLeft =='PRODUCT' ? 'animate__fadeInRight': 'animate__fadeOutLeft';
        vm.currentCaisseAnimate['DEPOTS'] = vm.currentCaisseLeft =='DEPOTS' ? 'animate__fadeInRight': 'animate__fadeOutLeft';
        vm.currentCaisseAnimate['CLIENTS'] = vm.currentCaisseLeft =='CLIENTS' ? 'animate__fadeInRight': 'animate__fadeOutLeft';
        
        

    }
    vm.toggleFullScreen = function () {
        var elem = document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    };
    vm.toggleFullScreen();

    vm.clearFilterProd = () => {
        vm.gamme_code = '';
        vm.famille_code = '';
        vm.marque_code = '';
        vm.sousfamille_code = '';
        vm.filter_prod='';
    }
    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarques = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectProdsByFilter = function () { 

        if(vm.soussociete_code && vm.client_code && vm.depot_code){
            vm.loading=true;
            WS.getData('produits/selectProdsByFilter',{
                tarification : vm.tarif,
                soussociete_code: vm.soussociete_code,
                gamme_code: vm.gamme_code,
                famille_code: vm.famille_code,
                sousfamille_code: vm.sousfamille_code,
                marque_code: vm.marque_code,
                search: vm.filter_prod,
            })
                .then(function (response) {
                    vm.produits = response.data;
                    vm.loading=false;
                    $scope.$apply();
                })
                .then(null, function (error) {
                    vm.loading=false;
                    console.log(error);
                });
        }else{
            swal(
                $translate.instant('Attention!'),
                $translate.instant('Veuillez bien vérifier le Distributeur, CLient et dépot avant d\'ajouter les produits'), 
                'warning'
            )
        }
        
    };
    vm.selectProdsByFilter();


    vm.selectDepots = function () {
        WS.get('depots/selectDepot').then(
            function (response) {
                vm.depots = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                if (vm.soussocietes.length == 1) {
                    vm.newbl.distributeur_code = vm.soussocietes[0].code;
                    vm.distributeur = vm.soussocietes[0]
                }
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };


    vm.onSelectSousSociete = (distributeur_code) => {
        vm.newbl.distributeur_code=distributeur_code;
        vm.distributeur  = vm.soussocietes.filter(ss => ss.code==distributeur_code)[0];

    }

    vm.onSelectDepot = (depot_code, user_code) => {
        vm.newbl.depot_code=depot_code;
        vm.newbl.commercial_code=user_code;
        vm.depot = vm.depots.filter(depot => depot.code==depot_code)[0];
        vm.changePageCaisse();
    }



    /**
     ** Gestion des événements de la page
     ** 
     */
     $window.addEventListener('keyup',e => {
        if(vm.asci_calc.includes(e.keyCode)){
            $(`#asci-${e.keyCode}`).addClass('calc-btn-hover');
            // Remove CSS class after 1 second
            setTimeout(function() {
                $(`#asci-${e.keyCode}`).removeClass('calc-btn-hover');
            }, 300);
        }
    })
}
export default {
    name: 'caisseVenteController',
    fn: caisseVenteController
};